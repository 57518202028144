
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Caxios } from "./../../utils/axios";
@Component({
  name: "Refund",
})
export default class Refund extends Vue {
  @Prop() refundItem!: any;

  private reasonText: string = "请选择退款原因";
  private reasonId: string = "";
  private reasonList: Array<any> = [{ text: "请选择退款原因", id: "" }];
  public personInfo: any = localStorage.getItem('fangtian_personal_Data') || ''
  
  private activeIndex: number = 0;
  private reasonListFlag: boolean = false;
  private refundInput: string = "";

  @Emit()
  private closeEvent(type: number): number {
    return type;
  }

  private selectItemEvent(item: any, index: number): void {
    this.activeIndex = index;
    this.reasonText = item.text;
    this.reasonId = item.id;
    this.reasonListFlag = false;
  }

  private showReasonList(): void {
    this.reasonListFlag = !this.reasonListFlag;
  }

  private async getReason() {
    this.reasonList = [{ text: "请选择退款原因", value: "" }];
    let _res: any = await Caxios.get({ url: "/api/datadict/REFUND_REASON" });
    this.reasonList = [...this.reasonList, ..._res];
  }
  private mouseleaveEvent(): void {
    this.reasonListFlag = false;
  }
  private sureCancelEvent(index: number): void {
    if (index == 1) {
      let _this: any = this;
      if (this.refundItem.paytype == "现金") {
        _this.$message.warning("您可以随时到附近校区前台领取现金退款！");
      } else {
        if (!this.reasonId) {
          _this.$message.warning("请选择退费原因！");
        } else {
          if (this.reasonId == "6") {
            if (!this.refundInput) {
              _this.$message.warning("请填写退费原因！");
            } else {
              this.orderRefund();
            }
          } else {
            this.orderRefund();
          }
        }
      }
    } else {
      this.closeEvent(0);
    }
  }
  private async orderRefund() {
    let _this: any = this,
      _reason: string =
        this.reasonId == "6"
          ? `${this.reasonText}-${this.refundInput}`
          : this.reasonText,
      _res: any = await Caxios.get({
        url: `/api/order/refund/${_this.refundItem.stdid}/${_this.refundItem.orderid}/1234?remark=${_reason}`,
      });
    if (_res == "SUCCESS") {
      _this.$message.success("预约退费成功，名额释放");
      this.closeEvent(1);
    } else {
      _this.$message.warning(_res.data.msg);
    }
  }
  beforeMount() {
    console.log(this.refundItem);
    this.getReason();
    if (this.personInfo) {
        this.personInfo = JSON.parse(this.personInfo)
    }
  }
}
