
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";
import { Caxios } from "./../../../utils/axios";
import PersonalAgreement from "./../../../components/personalAgreement/index.vue";
import Refund from "./../../../components/refund/index.vue";
import PayDialog from "./../../../components/payDialog/index.vue";
import PModal from "@/views/personal/components/pmodal.vue";
import HkHowpay from "@/views/personal/components/hkhowpay.vue";
import SModal from "@/views/personal/components/serviceAgree.vue";

@Component({
  name: "PersonalKc",
  computed: {
    ...mapGetters("personalKc", ["courseTotal", "courseList"]),
    ...mapGetters('common', ['branchSchoolId'])
  },
  methods: {
    ...mapActions("personalKc", ["setCourseTotal", "setCourseList"]),
  },
  components: { PersonalAgreement, Refund, PayDialog, PModal, HkHowpay, SModal },
})
export default class PersonalKc extends Vue {
  courseTotal!: Array<any>;
  courseList!: Array<any>;
  branchSchoolId!: number
  setCourseTotal!: any;
  setCourseList!: any;

  public checked = false
  public checkeds: Array<any> = []
  public greeFlag = false
  private loadingFlag: boolean = true;
  private activeIndex: number = 0;
  public personInfo: any = localStorage.getItem('fangtian_personal_Data') || ''

  private btnFlagList: Array<any> = []; // 预约退费按钮列表
  private orderFlagList: Array<any> = []; // 关闭订单按钮列表
  private reFundFlagList: Array<any> = []; // 退课按钮
  private navList: Array<any> = [
    { label: "To Be Paid Orders", num: 0, type: "unpaid", lessonType: "djf" },
    { label: "Currently Taking Courses", num: 0, type: "underway", lessonType: "zzs" },
    { label: "Upcoming Courses", num: 0, type: "unstart", lessonType: "jjs" },
    { label: "Completed courses", num: 0, type: "over", lessonType: "ysw" },
    { label: "Closed Courses", num: 0, type: "cancle", lessonType: "yqx" },
  ];
  private navLabel: Array<string> = [
    "To Be Paid",
    "Currently",
    "Upcoming",
    "Completed",
    "Closed",
  ];
  private tempObj: any = {
    stdid: "",
    s_type: "unpaid",
  };

  private personalKcList: Array<any> = [];
  private tableFlag: Array<boolean> = [];
  private personalTimer: Array<any> = [];
  private personalTimeStr: Array<string> = [];

  private personalTimer2: Array<any> = []
  private personalTestTimeStr: Array<string> = [];


  private hasSelectedArr: Array<boolean> = [];
  private curDate: string = "";
  private protocolPopType: number = 1;
  private protocolPopFlag: boolean = false;
  private protocolTitle: string = "";
  private protocolTitleArr: Array<string> = [
    "",
    "培训服务协议",
    "安全协议书",
    "调课协议",
    "转班协议",
  ];

  private refundFlag: boolean = false;
  private refundItem: any = {};

  private payDialogFlag: boolean = false;
  private payDialogItem: any = {};
  public visible: boolean = false

  @Watch("courseTotal", { immediate: true, deep: true })
  courseTotalWatch(newVal: any): void {
    let _this: any = this;
    _this.$set(_this.navList[0], "num", newVal.unpaid_total);
    _this.$set(_this.navList[1], "num", newVal.underway_total);
    _this.$set(_this.navList[2], "num", newVal.unstart_total);
    _this.$set(_this.navList[3], "num", newVal.over_total);
    _this.$set(_this.navList[4], "num", newVal.cancle_total);
  }
  private setChecked (index: number): void {
     this.$set(this.checkeds, index, !this.checkeds[index])
  }
  private toastMsg (): void {
     this.$message.error('Please read and sign the  Fangtian Training Service Agreement ！')
  }
  @Watch("courseList", { immediate: true, deep: true })
  courseListWatch(newVal: any): void {
    this.loadingFlag = false;

    let _this: any = this

    this.resetArr()

    if (newVal.length > 0) {
      this.personalKcList = [ ...newVal ];
      if (this.tableFlag.length == 0) {
        // this.tableFlag = new Array(newVal.length).fill().map(() => false);
        this.tableFlag = this.setArray(newVal.length, false)
      }
      this.personalTimer = new Array(newVal.length).fill(null);
      this.personalTimer2 = new Array(newVal.length).fill(null);
      this.personalTimeStr = new Array(newVal.length).fill("");
      this.personalTestTimeStr = new Array(newVal.length).fill('')
      this.hasSelectedArr = new Array(newVal.length).fill(false);
      if(this.activeIndex == 0) {
        this.personalKcList.map((item: any, index: number): void => {
          this.setTimeStr(item, index);
          this.$set(this.checkeds, index, false)
        });
        this.orderFlagList = this.personalKcList.map(() => true)
      }

      if (this.activeIndex == 1) {
        this.btnFlagList = this.personalKcList.map(() => true)
      }
      if(this.activeIndex == 2) {
        this.personalKcList.map((item: any, index: number): void => {
          this.setTimeStr2(item, index);
        });
        this.reFundFlagList = this.personalKcList.map(() => true)
      }
    }
  }


  private formatCountDown(_times: number, index: number): void {
    let _this: any = this,
      day: any = 0,
      hour: any = 0,
      minute: any = 0,
      second: any = 0; //时间默认值
    if (_times > 0) {
      day = Math.floor(_times / (60 * 60 * 24));
      hour = Math.floor(_times / (60 * 60)) - day * 24;
      minute = Math.floor(_times / 60) - day * 24 * 60 - hour * 60;
      second =
        Math.floor(_times) - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60;
    }
    // if (day === 0) day = day;
    if (day <= 9 && day > 0) day = "0" + day;
    if (hour <= 9) hour = "0" + hour;
    if (minute <= 9) minute = "0" + minute;
    if (second <= 9) second = "0" + second;
    _this.$set(
      _this.personalTimeStr,
      index,
      `${day} Day ${hour}:${minute}:${second}`
    );
  }
  private setTimeStr(item: any, index: number): void {
    let _times: number = Math.ceil(item.time_Long / 1000),
      _this: any = this;
    if (_times > -10 && !this.personalTimer[index]) {
      _this.formatCountDown(_times, index);
      _this.personalTimer[index] = setInterval(() => {
        _this.formatCountDown(_times, index);
        _times--;
        if (_times == -5) {
          clearInterval(this.personalTimer[index]);
          _this.$set(_this.personalTimer, index, null);
          this.changeActiveIndex(this.activeIndex)
        }
      }, 1000);

    }

  }
  // 动态生成数组
  private setArray(len: number, item: any): Array<any> {
    const arr: any = []
    for(let i = 0; i < len; i++) {
      arr.push(item)
    }
    return arr
  }

  private formatCountDown2(_times: number, index: number): void {
    let _this: any = this,
      day: any = 0,
      hour: any = 0,
      minute: any = 0,
      second: any = 0; //时间默认值
    if (_times > 0) {
      day = Math.floor(_times / (60 * 60 * 24));
      hour = Math.floor(_times / (60 * 60)) - day * 24;
      minute = Math.floor(_times / 60) - day * 24 * 60 - hour * 60;
    }
    if (day <= 9) day = "0" + day;
    if (hour <= 9) hour = "0" + hour;
    if (minute <= 9) minute = "0" + minute;

    _this.$set(
      _this.personalTestTimeStr,
      index,
      `${day}天${hour}小时${minute}分钟`
    );
  }
  private setTimeStr2(item: any, index: number): void {
    let _times: number = Math.ceil(item.time_Long / 1000),
      _this: any = this;
    if (_times > -10 && !this.personalTimer2[index]) {
      _this.formatCountDown2(_times, index);
      _this.personalTimer2[index] = setInterval(() => {
        _this.formatCountDown2(_times, index);
      _times--;
      if (_times == -5) {
        clearInterval(this.personalTimer[index]);
        _this.$set(_this.personalTimer, index, null);
          this.changeActiveIndex(this.activeIndex)
        }
      }, 1000);

    }
  }
  private resetArr(): void {
    this.personalKcList = [];

    let _this: any = this
    _this.personalTimer.forEach((item: any, index: number): void => {
      if(item) {
        clearInterval(this.personalTimer[index]);
        _this.$set(_this.personalTimer, index, null);
      }
    })

    _this.personalTimer2.forEach((item: any, index: number): void => {
      if(item) {
        clearInterval(this.personalTimer2[index]);
        _this.$set(_this.personalTimer2, index, null);
      }
    })

    this.personalTimeStr = [];
    this.personalTestTimeStr = []
    this.hasSelectedArr = [];
  }
  private changeActiveIndex(index: number): void {
    console.log(new Array(5), '返回空数组')
    this.tableFlag = []
    this.resetArr();
    this.activeIndex = index;
    this.tempObj.s_type = this.navList[index].type;
    this.setCourseTotal(this.tempObj);
    this.setCourseList(this.tempObj);
    this.loadingFlag = true;
  }
  private async changeTableFlag(item: any, index: number) {
    console.log(item, index, this.tableFlag, '查看')
    const _this:any = this;
    _this.tableFlag.forEach((v: boolean, i: number): void => {
      if (i != index) {
        _this.$set(_this.tableFlag, i, false);
      } else {
        _this.$set(_this.tableFlag, i, !v);
      }
    });
    console.log(_this.tableFlag, '....')
    if (this.tableFlag[index]) {
      let _this: any = this,
        _tempObj: any = {
          courseid: item.courseid,
          classid: item.classid,
          stdid: this.tempObj.stdid,
          type: this.navList[this.activeIndex].lessonType,
        },
        _res: any = await Caxios.get({
          url: `/api/official/classstatis/lesson/list?courseid=${_tempObj.courseid}&classid=${_tempObj.classid}&stdid=${_tempObj.stdid}&type=${_tempObj.type}`,
        });
      _this.$set(
        _this.personalKcList[index],
        "mineTimetable",
        Array.isArray(_res) && _res.length >= 0 ? _res : []
      );
    }
  }
  // 显示隐藏按钮
  changeBtnFlag (index: number) {
    switch (this.activeIndex) {
      case 0:
        this.$set(this.orderFlagList, index, true)
        break
      case 1:
        // this.btnFlag = true
          this.$set(this.btnFlagList, index, true)
        break
      case 2:
        this.$set(this.reFundFlagList, index, true)
        break
    }
  }
  // 点击显示按钮
  changeBtnFlag2 (index: number) {
    switch (this.activeIndex) {
      case 0:
        this.$set(this.orderFlagList, index, false)
        break
      case 1:
        this.$set(this.btnFlagList, index, false)
        break
      case 2:
        this.$set(this.reFundFlagList, index, false)
        break
    }
  }

 // 点击how to pay
  public hPay(): void {
    this.visible = true
  }
  // 点击ok
  public handleOk(): void {
    this.visible = false
    this.changeActiveIndex(0)
  }
  // 关闭弹窗
  public cancel(): void {
    this.visible = false
    this.changeActiveIndex(0)
  }
  // 关闭、退课订单
  private closeOrder(item: any, type: number) {
    let _this: any = this;
    _this
      .$confirm({
        title: "提示",//
        subTitle: type == 1 ? '确认要取消订单？' : '确认退出当前课程?',
        width: "400px",
        showClose: false,
        // input: true,
        btns: {
          sure: "确认",
          cancel: "取消",
        },
      })
      .then(async (msg: string) => {
        if (msg) {
        }
        let _res: any = await Caxios.get({
          url: `/api/order/delete/${item.orderid}/${_this.tempObj.stdid}`,
        });
        if (_res && _res.code != 10000) {
          _this.$message.warning(_res.msg);
        } else {
          _this.$message.success(type == 1 ? '取消订单成功！' : '退课成功！');
          _this.changeActiveIndex(this.activeIndex);
          this.initData()
        }
      })
      .catch(() => {
        // console.log('no')
      });
  }
  // 进入测试
  private goExam (item: any): void {}
  // 查看已测科目
  private goLookTest (item: any): void {
  }
  // 修改订单
  private changeClass (item: any): void {
    let _this: any = this
    sessionStorage.setItem('fangtian_person_to_course', JSON.stringify(item))
    sessionStorage.setItem('fangtian_person_to_course_orderid', item.orderid)
    _this.$router.replace(`/course/detail?courseid=${item.courseid}`)
  }
  // 缴费
  private async goPay(item: any, index: number, type: number) {
    let _this: any = this;
    if (!this.hasSelectedArr[index]) {
      _this.$message.warning("请先勾选相关协议");
    } else {
      //
      const _res: any = await Caxios.get({
        url: `/api/official/student/${this.tempObj.stdid}`,
      });
      if (_res.showInsure == 1) {
      } else {
        let _r: any = await Caxios.get({
          url: `/api/official/order/update/insurecode/${this.tempObj.stdid}/${_res.insurecode}/${item.orderid}`,
        });
        if (_r == "SUCCESS") {
          let _val: any = await Caxios.get({
            url: `/api/order/paycheck/${item.orderid}?orderId=${item.orderid}`,
          });
          if (_val.msg) {
            _this.$message.warning(_val.msg);
          } else {
            this.payDialogFlag = true;
            this.payDialogItem = { ...item, isWxOrAli: type };
          }
        }
      }
    }
  }
  private async goRefund(item: any) {
    let _this: any = this,
      _stdid: number = this.tempObj.stdid,
      _orderid: number = item.orderid,
      _res: any = await Caxios.get({
        url: `/api/order/refund/info/${_orderid}`,
      });
    if (_res && _res.paytype) {
      this.refundFlag = true;
      this.refundItem = {
        ..._res,
        stdid: _this.tempObj.stdid,
        orderid: item.orderid,
        classname: item.name,
      };
    } else {
      _this.$message.warning(_res.msg);
    }
  }

  private reOrder(item: any): void {
    /**
     * 选课中心修改courseid=0的请求
     */
    let _this: any = this;
    _this.$router.push({
      path: "/course/detail",
      query: { courseid: item.courseid },
    });
  }

  private async viewVideo(item: any) {
    // 查看回放
    let _this: any = this,
      _res: any = await Caxios.get({
        url: `/api/live/video/get?lessonId=${item.id}`,
      });
    if (Array.isArray(_res) && _res.length > 0) {
      if (_res.length == 1) {
        window.open(_res[0].videoRecordUrl);
      } else {
        _this.$message.warning("请向老师获取视频地址");
      }
    } else {
      _this.$message.warning("当前课次暂无回放内容");
    }
  }
  private async goppt(item: any) {
    const url = item.coursewareUrl
    window.open(url, '_blank')
  }
  private async goLive(item: any) {
    // 去上课
    let _this: any = this,
      _stdid: number = this.tempObj.stdid,
      _orderid: number = item.orderid,
      // _lessonid: any = item.lessonId,
      _res: any = await Caxios.get({
        url: `/api/live/checkTodayLesson?stdId=${_stdid}&orderId=${_orderid}`,
      });
    if (_res && _res.success == true) {
      _this.getLessonList(item, _stdid)
    } else {
      // if (_res.msg === "该班级今天没有线上课！") {
         _this.$message.warning("There are no classes in progress at the current time for this class!")
      // }else {
      // _this.$message.warning(_res.msg);
      // }
    }
  }
  // 筛选线上课程跳转直播课堂
  private async getLessonList (item: any, _stdid: string) {
    const _tempObj: any = {
      courseid: item.courseid,
      classid: item.classid,
      stdid: this.tempObj.stdid,
      type: this.navList[this.activeIndex].lessonType,
    }
    const _this:any = this
    const res: any = await Caxios.get({
      url: `/api/official/classstatis/lesson/list?courseid=${_tempObj.courseid}&classid=${_tempObj.classid}&stdid=${_tempObj.stdid}&type=${_tempObj.type}`,
    });
    if(res.length > 0) {
      const lessonId: string = res.find((item: any) => item.showLivingButtonStatus == '2')?.id
      if (lessonId) {
        const _res: any = await Caxios.get({
          url: `https://kangaroo.study/live2-service-singapore/live/v2/get/liveVersion?lessonId=${lessonId}&userId=${_stdid}&deviceType=web`
        })
        const _url = _res.url
        window.open(
                `${_url}`,
                "_blank"
        );
      } else {
        _this.$message.warning('There are no classes in progress at the current time for this class!')
      }

    }
  }
  private async goPostTest(item: any) {
    const _tempObj: any = {
      courseid: item.courseid,
      classid: item.classid,
      stdid: this.tempObj.stdid,
      type: this.navList[this.activeIndex].lessonType,
    }
    const _this:any = this
    const res: any = await Caxios.get({
      url: `/api/official/classstatis/lesson/list?courseid=${_tempObj.courseid}&classid=${_tempObj.classid}&stdid=${_tempObj.stdid}&type=${_tempObj.type}`,
    });
    if(res.length > 0) {
      const lessonId: string = res.find((item: any) => item.showLivingButtonStatus == '2')?.id

      if (lessonId) {
        // 做课后测
        let _stdid: number = this.tempObj.stdid,
                _orderid: number = item.orderid,
                _r: any = await Caxios.get({
                  url: `https://kangaroo.study/live2-service-singapore/live/v2/goPostTest?lessonId=${lessonId}&userId=${_stdid}&orderId=${_orderid}`,
                });
        console.log(_r)
        if (!_r.code) {
          console.log('成功')
          const _url = _r
          window.open(
                  `${_url}`,
                  "_blank"
          );
        } else {
          _this.$message.warning(_r.msg);
        }
      } else {
        _this.$message.warning('There are no classes in progress at the current time for this class!')
      }
    }
  }

  private selectArrEvent(index: number): void {
    let _this: any = this;
    _this.$set(_this.hasSelectedArr, index, !_this.hasSelectedArr[index]);
  }

  // 协议
  private protocolPopEvent(index: number): void {
    this.protocolPopType = index;
    this.protocolTitle = this.protocolTitleArr[index];
    this.protocolPopFlag = true;
  }
  // 关闭弹窗
  private closeEvent(type?: number): void {
    this.protocolPopType = -1;
    this.protocolTitle = "";
    this.protocolPopFlag = false;

    this.refundFlag = false;
    this.refundItem = {};
    this.payDialogFlag = false;
    this.payDialogItem = {};
    if (type) {
      this.resetArr();
      this.setCourseList(this.tempObj);
      this.setCourseTotal(this.tempObj);
    }
  }

  private initData (): void {
    let _this: any = this,
      _store: any = _this.$store.getters["personal/personalData"];
    this.tempObj.stdid = _store.id;
    this.resetArr();
    this.setCourseTotal(this.tempObj);
    this.setCourseList(this.tempObj);

    let _curDate: any = new Date(),
      yyyy: number = _curDate.getFullYear(),
      MM: string =
        _curDate.getMonth() + 1 < 10
          ? `0${_curDate.getMonth() + 1}`
          : `${_curDate.getMonth() + 1}`,
      dd: string =
        _curDate.getDate() < 10
          ? `0${_curDate.getDate()}`
          : `${_curDate.getDate()}`;
    this.curDate = `${yyyy}-${MM}-${dd}`;
  }
  mounted() {
    if (this.$route.query.openCourse === '1' && this.$route.query.lessonstatus) {
      const lessonstatus: any = this.$route.query.lessonstatus
      if(Number(this.$route.query.price) > 0) {
        this.changeActiveIndex(0)
      }else {
        if (lessonstatus == 0) {
          this.changeActiveIndex(2)
        } else if (lessonstatus == 1) {
          this.changeActiveIndex(3)
        } else if (lessonstatus == 2) {
          this.changeActiveIndex(1)
        }
      }
    }
  }
  beforeMount() {
    this.initData()
    if (this.personInfo) {
        this.personInfo = JSON.parse(this.personInfo)
    }
  }

  beforeDestroy() {
    this.resetArr()
  }
}
