
    import { Component, Vue, Emit, Watch, Prop } from 'vue-property-decorator'
    import { mapGetters } from 'vuex'

    @Component({
        name: 'SelectTimes',
        computed: mapGetters('personal', ['allFlag'])
    })
    export default class SelectTimes extends Vue {
        allFlag!: any
        @Prop() curTimes!: Array<any>
        @Prop() selectType!: string

        private subjectText: string = '请选择调课/转班课次'
        private subject: string = ''
        private itemClickedFalg: boolean = false
        private mouseoverFlag: boolean = false
        private activeIndex: number = -1
        private listFlag: boolean = false
        private curSelectType: string = ''
        private curTimesList: Array<any> = [
            { text: '请选择调课/转班课次', id: ''}
        ]
        @Watch('allFlag', { immediate: true, deep: true })
        allFlagWatch(newVal: number): void {
            if(newVal != -1) {
                this.listFlag = false
                this.mouseoverFlag = false
                this.curtimesCb(0)
            }
        }
        @Watch('curTimes', { immediate: true, deep: true })
        curTimesWatch(newVal: Array<any>): void {
            this.curTimesList = [ { text: '请选择调课/转班课次', id: '', mineText: '请选择调课/转班课次', mineDisMsg: '' } ]
            if(newVal.length > 0) {
                
                let _this: any = this
                newVal.map((item: any, index: number): void => {
                    if(item.lessonstatus == '0') {
                         item.mineText = `${item.text}`
                         item.mineDisMsg = ''
                    } else if(item.lessonstatus == '1') {
                        let _arr: Array<string> = item.text.split('【'),
                            _temp: any = { item };
                        item.mineText = _arr[0]
                        item.mineDisMsg = _arr[1] ? `【${_arr[1]}` : ''
                    } else if(item.lessonstatus == '2') {
                        item.mineText = `${item.text}`
                        item.mineDisMsg = `【正在上】`
                    }
                })
                this.curTimesList = [
                    ...this.curTimesList,
                    ...newVal
                ]
            } else {
                this.subjectText = '请选择调课/转班课次'
                this.curTimesList = [
                    { text: '请选择调课/转班课次', id: ''}
                ]
                this.subject = ''
            }
        }
        @Watch('selectType', { immediate: true, deep: true })
        selectTypeWatch(newVal: string, oldVal: string): void {
            if(newVal && newVal.length > 0) {
                this.curSelectType = newVal
                if(newVal == 'CLASS') {
                    let _index: number = -1
                    this.curTimesList.forEach((item: any, index: number): void => {
                        if(_index == -1 && item.lessonstatus == '0') {
                            _index = index
                        }
                    })
                    this.subjectText = `Classes：${this.curTimesList[_index].number} ~ ${this.curTimesList[this.curTimesList.length - 1].number}`
                }
                if(oldVal && oldVal == 'CLASS') {
                    this.curSelectType = ''
                    this.subjectText = '请选择调课/转班课次'
                }
            } else {
                this.curSelectType = ''
                this.subjectText = '请选择调课/转班课次'
            }
        }
        private mouseoverEvent (): void {
            this.mouseoverFlag = true
            this.curtimesCb(0)
        }
        @Emit()
        private curtimesCb(status: number, obj?: any): any {
            return obj ? { ...obj, status: status } : { status: status }
        }
        private listFlagEvent (): void {
            this.listFlag = !this.listFlag
        }
        private changeActiveEvent (item: any, index: number): void {
            if(item.mineDisMsg != '【已下课】' && item.mineDisMsg != '【正在上】') {
                this.subjectText = item.text
                this.subject = item.id
                this.itemClickedFalg = true
                this.mouseoverFlag = false
                this.listFlag = false
                this.curtimesCb(1, item)
            }
        }
    }
