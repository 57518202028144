
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";
import PersonalKc from "./personalKc/index.vue";
import PersonalTtl from "./personalTtl/index.vue";
import PersonalSc from "./personalSc/index.vue";
import PersonalZdzk from "./personalZdzk/index.vue";
import PersonalKscf from "./personalKscf/index.vue";
import EditComp from "./edit/index.vue";
const info = require("./../../assets/imgs/pic_info.png"),
  kc = require("./../../assets/imgs/pic_kc.png"),
  ttl = require("./../../assets/imgs/pic_ttl.png"),
  zy_sc = require("./../../assets/imgs/pic_zy_sc.png"),
  zdzk = require("./../../assets/imgs/pic_zdzk.png"),
  kscf = require("./../../assets/imgs/pic_kscf.png");
@Component({
  name: "Personal",
  components: {
    PersonalKc,
    PersonalTtl,
    PersonalSc,
    PersonalZdzk,
    PersonalKscf,
    EditComp,
  },
  computed: {
    ...mapGetters("personal", ["personalData", "hasWorkFlag"])
  },
  methods: {
    ...mapActions('personal', ['getDailyWorksCount']),
  }
})
export default class Personal extends Vue {
  getDailyWorksCount!: any
  personalData!: any;
  hasWorkFlag!: boolean;
  private slidebarList: Array<any> = [
    { text: "个人信息", icon: info, componentName: "EditComp" },
    { text: "我的课程", icon: kc, componentName: "PersonalKc" },
    { text: "天天练", icon: ttl, componentName: "PersonalTtl" },
    { text: "作业收藏夹", icon: zy_sc, componentName: "PersonalSc" },
    { text: "自助调课转班", icon: zdzk, componentName: "PersonalZdzk" },
    { text: "考试查分", icon: kscf, componentName: "PersonalKscf" },
  ];
  private slideList: Array<any> = [
    { text: "Personal Information", icon: info, componentName: "EditComp" },
    { text: "Study",
      icon: kc,
      children:[
        { text: "My Courses", icon: kc, componentName: "PersonalKc" },
        { text: "Do homework", icon: ttl, componentName: "PersonalTtl" },
        { text: "Assignment Favorites", icon: zy_sc, componentName: "PersonalSc" }
      ]
    },
    { text: "Academic Affairs",
      icon: info,
      children:[
        { text: "Transfer Classes", icon: zdzk, componentName: "PersonalZdzk" },
        { text: "Test Score Checking", icon: kscf, componentName: "PersonalKscf" }
      ]
    }
  ];
  private componentName: string = 'EditComp'
  private editFlag = false;
  private curHasWorkFlag: boolean = false
  private flag = 0
  private menuFlag = false
  private flags = [false, false]


  @Watch('hasWorkFlag', { immediate: true, deep: true })
  hasWorkFlagWatch(newVal: boolean): void {
    this.curHasWorkFlag = newVal
  }
  // @Watch('$route', { immediate: true, deep: true })
  // selectItem(newVal: any): void {
  //   if(newVal.query.openCourse) {
  //     this.menuFlag = true
  //     this.changeComponent(this.slideList[1], -1, 0)
  //     setTimeout(() => {
  //       this.changeComponent(this.slideList[1], 1, 0)
  //     },500)
  //
  //   }
  // }

  private changeComponent(item: any, index: number, type: number): void {
    console.log(item, index, type)
    if ((index === 0 && type === 0) || type === 1) {
      this.componentName = item.componentName;
    }
    // if (index !== 0 && type === 0) {
    //   this.flag = index
    // }
    this.flag = index
  }
  mounted() {
    if (this.$route.query.openCourse === '1' && this.$route.query.lessonstatus) {
      this.menuFlag = true
      // this.changeComponent(this.slideList[1], -1, 0)
      setTimeout(() => {
        this.changeComponent(this.slideList[1], 1, 0)
        this.componentName = 'PersonalKc'
      },500)
    }
  }
  beforeMount() {
    let _this: any = this,
      _store: any = _this.$store.getters["personal/personalData"];
    // this.getDailyWorksCount(_store.id);
  }
}
