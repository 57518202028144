
    import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'

    import { checkPhone } from './../../utils/validate'

    import { mapGetters } from 'vuex'

    @Component({
        name: 'EditCard',
        computed: mapGetters('personal', ['attrEmptyFlag'])
    })
    export default class EditCard extends Vue {
        attrEmptyFlag!: number
        @Prop() placeholder!: string
        @Prop() type!: any

        private identificationCard: string = ''
        private status: number = 0
        
        private focusEvent(): void {
            this.status = 0
            this.EditcardCb(0)
        }

        @Watch('attrEmptyFlag', { immediate: true, deep: true })
        attrEmptyFlagWatch(newVal: number): void {
            let _this: any = this
            if(_this.$route.path == '/personal/index') {
                this.curIdentificationCard()
            }
        }

        @Emit()
        private EditcardCb(status: number): any {
            return { identificationCard: this.identificationCard, status: status }
        }
        private blurEvent (): void {
            if(this.identificationCard) {
                this.status = 1
            } else {
                this.status = 0
            }
            this.EditcardCb(1)
        }
        private curIdentificationCard (): void {
            let personalData: any = localStorage.getItem('fangtian_personal_Data')
            if(personalData) {
                personalData = JSON.parse(personalData)
                this.identificationCard = personalData.identificationCard || personalData.identification_card
            }
        }
        beforeMount() {
            this.curIdentificationCard()
        }
    }
