import { render, staticRenderFns } from "./index.vue?vue&type=template&id=f67e91b2&scoped=true"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=f67e91b2&prod&scoped=true&lang=stylus&rel=stylesheet%2Fstylus"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f67e91b2",
  null
  
)

export default component.exports